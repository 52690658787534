@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-image: url('../public/DALL·E 2023-10-13 19.23.11 - Render of a 3D hexagonal pattern in a rich yellow shade, giving an impression of depth and texture akin to a beehive. The hexagons are characterized b.png'); 
   background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;  /* This will center everything horizontally */
  width: 100%;  /* Ensures the container takes the full width of its parent */
  gap: 20px;  /* Adjust this value to your desired padding */
  background-color: aliceblue;
}

.form {
  background-color:white;
  width:75%
}
